import { defineAsyncComponent } from 'vue';
import { ComponentDefinition } from '../common/models/app/component-definition.model';

export const components: Array<ComponentDefinition> = [
    { tag: 'rad-share-v1', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-share-v1" */ `./social/ShareV1.vue`)) },
    { tag: 'rad-navigation-sidebar', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-navigation-sidebar" */ `./navigation/sidebar/Sidebar.vue`)) },
    { tag: 'rad-navigation-sidebar-level-b', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-navigation-sidebar-level-b" */ `./navigation/sidebar/SidebarLevelB.vue`)) },
    { tag: 'rad-navigation-sidebar-level-c', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-navigation-sidebar-level-c" */ `./navigation/sidebar/SidebarLevelC.vue`)) },
    { tag: 'rad-navigation-sidebar-button', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-navigation-sidebar-button" */ `./navigation/sidebar/SidebarButton.vue`)) },
    { tag: 'rad-search-searchbar', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-search-searchbar" */ `./search/SearchBar.vue`)) },
    { tag: 'rad-affix', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-affix" */ `./structure/Affix.vue`)) },
    { tag: 'rad-carousel-simple', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-carousel-simple" */ `./carousel/simple.vue`)) },
    { tag: 'rad-carousel-simple-card', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-carousel-simple-card" */ `./carousel/simple-card.vue`)) },
    { tag: 'rad-newsletter-signup-wide', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-wide" */ `./newsletters/NewsletterSignupWide.vue`)) },
    { tag: 'rad-newsletter-signup-wordpress-narrow', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-wordpress-narrow" */ `./newsletters/NewsletterSignupWordpressNarrow.vue`)) },
    { tag: 'rad-newsletter-signup-wordpress-wide', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-wordpress-wide" */ `./newsletters/NewsletterSignupWordpressWide.vue`)) },
    { tag: 'rad-newsletter-signup-wide-more-like-this', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-wide-more-like-this" */ `./newsletters/NewsletterSignupWideMoreLikeThis.vue`)) },
    { tag: 'rad-newsletter-signup-better-you', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-better-you" */ `./newsletters/NewsletterSignupBetterYou.vue`)) },
    { tag: 'rad-newsletter-signup-small-image', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-small-image" */ `./newsletters/NewsletterSignupSmallImage.vue`)) },
    { tag: 'rad-newsletter-signup-large-image', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-large-image" */ `./newsletters/NewsletterSignupLargeImage.vue`)) },
    { tag: 'rad-newsletter-gallery', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-gallery" */ `./newsletters/NewsletterSignupGallery.vue`)) },
    { tag: 'rad-jwplayer', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-jwplayer" */ `./jwplayer/JWPlayer.vue`)) },
    { tag: 'rad-contact-us', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-contact-us" */ `./emails/ContactUs.vue`)) },
    { tag: 'rad-advertise-with-us', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-advertise-with-us" */ `./emails/AdvertiseWithUs.vue`)) },
    { tag: 'rad-google-recaptcha', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-google-recaptcha" */ `./google/ReCaptcha.vue`)) },
    { tag: 'rad-omatic-quiz', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-omatic-quiz" */ `./quiz/OMatic.vue`)) },
    { tag: 'rad-quiz-poll', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-poll" */ `./quiz/Poll.vue`)) },
    { tag: 'rad-quiz-poll-sidebar', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-poll-sidebar" */ `./quiz/PollSidebar.vue`)) },
    { tag: 'rad-quiz-trivia', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-trivia" */ `./quiz/Trivia.vue`)) },
    { tag: 'rad-quiz-trivia-question', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-trivia-question" */ `./quiz/trivia/Question.vue`)) },
    { tag: 'rad-quiz-trivia-answer', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-trivia-answer" */ `./quiz/trivia/Answer.vue`)) },
    { tag: 'rad-quiz-trivia-result', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-trivia-result" */ `./quiz/trivia/Result.vue`)) },
    { tag: 'rad-quiz-personality', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-personality" */ `./quiz/Personality.vue`)) },
    { tag: 'rad-quiz-personality-question', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-personality-question" */ `./quiz/personality/Question.vue`)) },
    { tag: 'rad-quiz-personality-answer', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-personality-answer" */ `./quiz/personality/Answer.vue`)) },
    { tag: 'rad-quiz-personality-result', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-personality-result" */ `./quiz/personality/Result.vue`)) },
    { tag: 'rad-quiz-builder', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-builder" */ `./quiz/Builder.vue`)) },
    { tag: 'rad-quiz-onepage', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-onepage" */ `./quiz/OnePage.vue`)) },
    { tag: 'rad-quiz-onepage-question', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-onepage-question" */ `./quiz/onepage/Question.vue`)) },
    { tag: 'rad-quiz-onepage-answer', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-onepage-answer" */ `./quiz/onepage/Answer.vue`)) },
    { tag: 'rad-quiz-onepage-result', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-onepage-result" */ `./quiz/onepage/Result.vue`)) },
    { tag: 'rad-newsletter-signup-subscribe', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-subscribe" */ `./newsletters/signup/Subscribe.vue`)) },
    { tag: 'rad-newsletter-signup-subscribe2', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-subscribe2" */ `./newsletters/signup/Subscribe2.vue`)) },
    { tag: 'rad-newsletter-signup-card', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-card" */ `./newsletters/signup/Card.vue`)) },
    { tag: 'rad-newsletter-signup-manage', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-manage" */ `./newsletters/signup/Manage.vue`)) },
    { tag: 'rad-saints', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-saints" */ `./saints/Page.vue`)) },
    { tag: 'rad-columnists-list', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-columnists-list" */ `./columnists/List.vue`)) },
    { tag: 'rad-columnists-columnist', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-columnists-columnist" */ `./columnists/Columnist.vue`)) },
    { tag: 'rad-contributing-writers-bio', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-contributing-writers-bio" */ `./contributing-writers/Bio.vue`)) },
    { tag: 'rad-carousel-believe-in', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-carousel-believe-in" */ `./carousel/believe-in.vue`)) },
    { tag: 'rad-carousel-believe-in-card', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-carousel-believe-in-card" */ `./carousel/believe-in-card.vue`)) },
    { tag: 'rad-carousel-homepage-card', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-carousel-homepage-card" */ `./carousel/homepage-card.vue`)) },
    { tag: 'rad-newsletter-signup-retention', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-retention" */ `./newsletters/signup/Retention.vue`)) },
    { tag: 'rad-daily-content-top-reading', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-daily-content-top-reading" */ `./daily-content/TopReading.vue`)) },
    { tag: 'rad-daily-content-daily-reading-prayer', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-daily-content-daily-reading-prayer" */ `./daily-content/DailyReadingPrayer.vue`)) },
    { tag: 'rad-related-content-more-from-beliefnet', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-related-content-more-from-beliefnet" */ `./related-content/MoreFromBeliefnet.vue`)) },
    { tag: 'rad-celeb-faith-db-promo-cfd-grid-promo', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-celeb-faith-db-promo-cfd-grid-promo" */ `./celeb-faith-db-promo/CfdGridPromo.vue`)) },
    { tag: 'rad-navigation-index', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-navigation-index" */ `./navigation/index/Index.vue`)) },
    { tag: 'rad-coreg-interstitial', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-coreg-interstitial" */ `./pathfinder/CoRegInterstitial.vue`)) },
    { tag: 'rad-quiz-custom', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-custom" */ `./quiz/Custom.vue`)) },
    { tag: 'rad-quiz-custom-question', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-custom-question" */ `./quiz/custom/Question.vue`)) },
    { tag: 'rad-quiz-custom-answer', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-custom-answer" */ `./quiz/custom/Answer.vue`)) },
    { tag: 'rad-quiz-newsletter', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-newsletter" */ `./quiz/Newsletter.vue`)) },
    { tag: 'rad-quiz-custom-result', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-custom-result" */ `./quiz/custom/Result.vue`)) },
    { tag: 'rad-content-link', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-content-link" */ `./content/link.vue`)) },
    { tag: 'rad-admiral-login', component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-admiral-login" */ `./admiral/Login.vue`)) }
];
